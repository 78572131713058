import { getArtworks } from "../api/artwork";

export function getKeyByValue(object, value) {
	return Object.keys(object).find((key) => object[key] === value);
}

export const delay = (n) => new Promise((r) => setTimeout(r, n * 1000));

export const getNonEmpty = (field) => {
	const defaultLanguage = "es";
	if (!field || !Object.keys(field)?.length) return "----";
	if (field[defaultLanguage] && field[defaultLanguage] !== "")
		return field[defaultLanguage];
	for (const lang of Object.keys(field)) {
		if (field[lang] && field[lang] !== "") return field[lang];
	}
	return "----";
};

export const checkIsEmpty = (field) => {
	return Object.values(field).every(
		(x) => x === null || x === "" || x === " "
	);
};

export const getFileType = (fileName) => {
	if (!fileName) return "unknown";
	const ext = fileName.split(".").pop();
	switch (ext) {
		case "pdf":
			return "file";
		case "m4a":
			return "audio";
		case "mp3":
			return "audio";
		case "mpa":
			return "audio";
		case "wma":
			return "audio";
		case "aif":
			return "audio";
		case "cda":
			return "audio";
		case "mid":
			return "audio";
		case "midi":
			return "audio";
		case "ogg":
			return "audio";
		case "wav":
			return "audio";
		case "wpl":
			return "audio";
		case "jpg":
			return "image";
		case "jpeg":
			return "image";
		case "png":
			return "image";
		case "svg":
			return "image";
		case "gif":
			return "image";
		case "avi":
			return "video";
		case "m4v":
			return "video";
		case "mkw":
			return "video";
		case "mp4":
			return "video";
		case "wmv":
			return "video";
		case "mov":
			return "video";
		default:
			return "unknown";
	}
};

function areWordsSimilar(word1, word2, threshold = 2) {
	if (word1 === "Untitled" || word2 === "Untitled"){
		return false;
	}

	const matrix = Array.from({ length: word1.length + 1 }, (_, i) =>
		Array.from({ length: word2.length + 1 }, (_, j) =>
			i === 0 ? j : j === 0 ? i : 0
		)
	);

	for (let i = 1; i <= word1.length; i++) {
		for (let j = 1; j <= word2.length; j++) {
			if (word1[i - 1] === word2[j - 1]) {
				matrix[i][j] = matrix[i - 1][j - 1];
			} else {
				matrix[i][j] = Math.min(
					matrix[i - 1][j] + 1,
					matrix[i][j - 1] + 1,
					matrix[i - 1][j - 1] + 1
				);
			}
		}
	}

	const levenshteinDistance = matrix[word1.length][word2.length];

	return levenshteinDistance <= threshold;
}

export const findSimilarArtworks = ({ artworks, artwork }) => {
	const result = artworks?.filter(
		(x) =>
			x?._id !== artwork?._id &&
			(areWordsSimilar(x?.title, artwork?.title) ||
				(x.artworkType?._id === artwork?.artworkType &&
					x.artist?._id === artwork?.artist &&
					x.originCountry?._id === artwork?.originCountry))
	);
	let count0 = 0;
	let count1 = 0;
	let count2 = 0;
	let count3 = 0;
	for(let x of result){
		console.log(x?.title);
		console.log(artwork?.title);
		console.log(count0 += areWordsSimilar(x?.title, artwork?.title));
		console.log(x.artworkType?._id);
		console.log(artwork?.artworkType);
		console.log(count1 += x.artworkType?._id === artwork?.artworkType);
		console.log(x.artist?._id);
		console.log(artwork?.artist);
		console.log(count2 += x.artist?._id === artwork?.artist);
		console.log(x.originCountry?._id);
		console.log(artwork?.originCountry);
		console.log(count3 += x.originCountry?._id === artwork?.originCountry);
		console.log("-------------------");
		console.log("Otra obra ");
		console.log("-------------------");
	}
	console.log(count0);
	console.log(count1);
	console.log(count2);
	console.log(count3);
	return result;
};
