import { Button, TextField, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { deleteArtist, getArtists } from "../../../../api/artist";
import { getArtisticMovements } from "../../../../api/artisticMovement";
import { getArtistTypes } from "../../../../api/artistType";
import { getCountries } from "../../../../api/country";
import { postLog } from "../../../../api/log";
import { LOGS } from "../../../../utils/constants";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import Table, {
  buttonsStyle,
  getCurrentTablePage,
} from "../../../components/tables/table";
import { Autocomplete } from "@material-ui/lab";

function getData(artists) {
  let data = [];
  for (let i = 0; i < artists.length; ++i) {
    const elem = {};
    const artist = artists[i];

    elem.fullName = artist?.fullName;
    elem.artisticMovement = artist?.artisticMovement
      ?.map((x) => x.name)
      ?.join(", ");
    elem.nationality = artist?.nationality?.map((x) => x.name)?.join(", ");
    elem.artistType = artist?.artistType?.map((x) => x.title)?.join(", ");
    elem.birthDate = artist?.birthDate;
    elem.id = artist?._id;

    data = data.concat(elem);
  }
  return data;
}

const initialFilters = {
  artistType: "",
  artisticMovement: "",
  nationality: "",
};

export default function ArtistsPage() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [selectedArtist, setSelectedArtist] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [artistTypes, setArtistTypes] = useState([]);
  const [artisticMovements, setArtisticMovements] = useState([]);
  const [countries, setCountries] = useState([]);

  const [filterOptions, setFilterOptions] = useState(initialFilters);

  const location = useLocation();

  const history = useHistory();
  const loggedUser = useSelector(
    (store) => store.authentication?.user,
    shallowEqual
  );

  function buttonFormatter(cell) {
    const elem = data.find((item) => item._id === cell);
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              history.push({
                pathname: "/artists/" + cell + "/edit",
                state: {
                  page: getCurrentTablePage(location),
                },
              });
            }}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setSelectedArtist(elem);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </>
    );
  }

  const buildDate = (date) => {
    if (Object.prototype.toString.call(date) !== "[object Date]") return;
    const displayDate = `${date.getDate()}/${date.getMonth() +
      1}/${date.getFullYear()}`;
    return displayDate;
  };

  function dateFormatter(cell) {
    let date = new Date(cell);
    date = buildDate(date);
  
    return date;
  }

  const columns = [
    { dataField: "fullName", text: "Full Name", sort: true },
    {
      dataField: "artisticMovement",
      text: "Artistic Movement",
      sort: true,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "nationality",
      text: "Nationality",
      sort: true,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "artistType",
      text: "Artist Type",
      sort: true,
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "birthDate",
      text: "Birth Date",
      formatter: dateFormatter,
      sort: true,
    },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getArtistTypes()
      .then((res) => {
        if (res.status === 200) {
          setArtistTypes(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get artist types.",
        });
      });
    getArtisticMovements()
      .then((res) => {
        if (res.status === 200) {
          setArtisticMovements(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get artistic movements.",
        });
      });
    getCountries()
      .then((res) => {
        if (res.status === 200) {
          setCountries(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get countries.",
        });
      });
    getArtists()
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setFilteredData(res.data);
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get artists.",
        });
      });
  }, [refresh]);

  useEffect(() => {
    handleSearch();
  }, [filterOptions]);

  const handleSearch = async () => {
    if (!data.length) return;
    setFilteredData(
      data.filter((item) => {
        let filter = true;
        if (filterOptions.artistType)
          filter =
            filter &&
            item?.artistType?.some(
              (x) => x._id === filterOptions?.artistType?._id
            );

        if (filterOptions.artisticMovement)
          filter =
            filter &&
            item?.artisticMovement?.some(
              (x) => x._id === filterOptions?.artisticMovement?._id
            );

        if (filterOptions.nationality)
          filter =
            filter &&
            item?.nationality?.some(
              (x) => x._id === filterOptions?.nationality?._id
            );

        if (filter) return item;
        return false;
      })
    );
  };

  const handleClearFilters = () => {
    setFilterOptions(initialFilters);
  };

  function renderFilterContent() {
    return (
      <div className="row d-flex align-items-center">
        <div className="col-md-4">
          <Autocomplete
            id="autocomplete-artistType"
            disablePortal
            options={artistTypes}
            getOptionLabel={(option) => option.title}
            value={filterOptions?.artistType}
            onChange={(event, selected) => {
              setFilterOptions({
                ...filterOptions,
                artistType: selected,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="filter-field"
                label="Artist Type"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>
        <div className="col-md-4">
          <Autocomplete
            id="autocomplete-artist"
            disablePortal
            options={artisticMovements}
            getOptionLabel={(option) => option?.name}
            value={filterOptions?.artisticMovement}
            onChange={(event, selected) => {
              setFilterOptions({
                ...filterOptions,
                artisticMovement: selected,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="filter-field"
                label="Artistic Movement"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>
        <div className="col-md-4">
          <Autocomplete
            id="autocomplete-country"
            disablePortal
            options={countries}
            getOptionLabel={(option) => option?.name}
            value={filterOptions?.nationality}
            onChange={(event, selected) => {
              setFilterOptions({
                ...filterOptions,
                nationality: selected,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="filter-field"
                label="Origin Country"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Card>
        <CardHeader title="Artist list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                history.push({
                  pathname: "/artists/edit",
                  state: {
                    page: getCurrentTablePage(location),
                  },
                });
              }}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={getData(filteredData)}
            columns={columns}
            filters={renderFilterContent()}
            resetFilters={handleClearFilters}
          />
          <ConfirmDialog
            title={"Are you sure you want to delete this artist?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteArtist(selectedArtist?._id)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    postLog(
                      loggedUser,
                      LOGS.FIELDS.ARTISTS,
                      `${LOGS.ACTIONS.DELETE}-${selectedArtist?.fullName}`
                    );
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Artist deleted successfully.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not delete artist.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
